import React, { FC, lazy, PropsWithChildren, Suspense } from "react";
import ReactDOM from "react-dom/client";

import Loader from "./components/UI/Loader";
import reportWebVitals from "./seo/reportWebVitals";

const App = lazy(() => import("./components/App"));

export type FCWithChildren<T> = FC<PropsWithChildren<T>>;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
