import React from "react";

import { PacmanLoader } from "react-spinners";

import styles from "./Loader.module.scss";

const Loader = () => {
  return (
    <div className={styles.wrapper}>
      <PacmanLoader size={40} color="#76bc21" />
    </div>
  );
};

export default Loader;
